export const authRoutes = [
  {
    name: 'login',
    path: '/auth/login',
    component: () => import('@/pages/auth/Login.vue'),
    meta: {
      isAuthPage: true,
    },
  },
  {
    name: 'register',
    path: '/auth/register',
    component: () => import('@/pages/auth/Register.vue'),
    meta: {
      isAuthPage: true,
    },
  },
  {
    name: 'forgot-password',
    path: '/auth/forgot-password',
    component: () => import('@/pages/auth/ForgotPassword.vue'),
    meta: {
      isAuthPage: true,
    },
  },
  {
    name: 'reset-password',
    path: '/user/:uid/:token/reset-password',
    component: () => import('@/pages/auth/ResetPassword.vue'),
    meta: {
      isAuthPage: true,
    },
  },
];

export default authRoutes;
