import RequestRoutes from '@/router/RequestsRoutes';

const SidebarRoutes = [
  {
    path: '',
    component: () => import('@/layouts/SidebarLayout.vue'),
    children: [
      ...RequestRoutes,
    ],
  },
];

export default SidebarRoutes;
