import { defineStore } from 'pinia';
import types from '@/types';
import ContractorService from '@/services/ContractorService';

const useContractorService = new ContractorService();

const useContractorStore = defineStore('contractor', {
  state: () => ({
    contractor: null,
  }),
  actions: {
    async [types.GET_CONTRACTOR]() {
      try {
        this.contractor = await useContractorService.get_contractor_info();
      } catch (error) {
        if (error.response.data?.detail) {
          this.contractor = 'loaded';
        }
      }
    },
  },
});

export default useContractorStore;
