const RequestRoutes = [
  {
    path: '',
    name: 'home',
    component: () => import('@/pages/HomePage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/request/:id/employees',
    name: 'request-employees',
    component: () => import('@/pages/request-employees/EmployeesRequest.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/request/:id/employees/add',
    name: 'request-employee-add',
    component: () => import('@/pages/request-employees/AddEmployee.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/request/:id/employees/add/:employeeId',
    name: 'request-employee-edit',
    component: () => import('@/pages/request-employees/AddEmployee.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/request/:id/checkout',
    name: 'request-contractors-checkout',
    component: () => import('@/pages/payments/EmployeesCheckoutPage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/request/register',
    name: 'request-register',
    component: () => import('@/pages/request/ContractorRegister.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/request/register/:id/',
    name: 'request-register-edit',
    component: () => import('@/pages/request/ContractorRegister.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/request/register/:id/checkout/',
    name: 'request-register-payment',
    component: () => import('@/pages/request/ContractorRegisterCheckout.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'profile',
    path: '/profile',
    component: () => import('@/pages/auth/UserProfile.vue'),
    meta: {
      requiresAuth: true,
    },
  },
];

export default RequestRoutes;
