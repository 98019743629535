import { createRouter, createWebHashHistory } from 'vue-router';
import AuthRoutes from '@/router/AuthRoutes';
import guardRouter from '@/router/GuardRouter';
import SidebarRoutes from '@/router/SidebarRoutes';

const routes = [
  ...AuthRoutes,
  ...SidebarRoutes,
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    redirect: '/',
  },
];

const homeRoute = routes.find((route) => route.path === '/home');
if (homeRoute) homeRoute.path = '/';

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

guardRouter(router);

export default router;
