export default {
  // App
  SETUP_APP: 'SETUP_APP',
  SHOW_NOTIFICATION: 'SHOW_NOTIFICATION',
  CLOSE_NOTIFICATION: 'CLOSE_NOTIFICATION',
  GET_NATIONALITIES: 'GET_NATIONALITIES',
  GET_CONTRACTOR_MATERIAL: 'GET_CONTRACTOR_MATERIAL',

  // User
  LOGIN: 'LOGIN',
  RESET_PASSWORD: 'RESET_PASSWORD',
  RECOVER_PASSWORD: 'RECOVER_PASSWORD',
  REGISTER: 'REGISTER',
  LOGOUT: 'LOGOUT',
  GET_USER: 'GET_USER',
  SET_TOKEN: 'SET_TOKEN',
  IS_LOGGED_IN: 'IS_LOGGED_IN',
  GET_DATA_FROM_PEOPLE_SERVICE: 'GET_DATA_FROM_PEOPLE_SERVICE',
  GET_DATA_BY_IDENTIFICATION: 'GET_DATA_BY_IDENTIFICATION',

  // Requests
  GET_REQUESTS: 'GET_REQUESTS',
  GET_REQUEST_TYPES: 'GET_REQUEST_TYPES',
  GET_REQUEST_STATUSES: 'GET_REQUEST_STATUSES',
  GET_REQUEST_IDENTIFICATION_TYPES: 'GET_REQUEST_IDENTIFICATION_TYPES',
  CREATE_REQUEST: 'CREATE_REQUEST',
  UPDATE_REQUEST: 'UPDATE_REQUEST',
  SET_DOCUMENT_REQUEST: 'SET_DOCUMENT_REQUEST',
  GET_REQUEST_DOCUMENTS: 'GET_REQUEST_DOCUMENTS',
  SET_DOCUMENT_CARNET_REQUEST: 'SET_DOCUMENT_CARNET_REQUEST',
  CLEAR_DOCUMENTS_CARNET_REQUEST: 'CLEAR_DOCUMENTS_CARNET_REQUEST',
  GET_REQUEST_CARD_DOCUMENTS: 'GET_REQUEST_CARD_DOCUMENTS',
  REQUEST_ADD_CONTRACTOR: 'REQUEST_ADD_CONTRACTOR',
  REQUEST_UPDATE_CONTRACTOR: 'REQUEST_UPDATE_CONTRACTOR',
  REQUEST_VALIDITIES: 'REQUEST_VALIDITIES',
  CREATE_REQUEST_HEAD: 'CREATE_REQUEST_HEAD',
  GET_REQUEST_HEAD: 'GET_REQUEST_HEAD',
  GET_REQUEST_CARD_EMPLOYEE: 'GET_REQUEST_CARD_EMPLOYEE',
  GET_FILE_CONFIG: 'GET_FILE_CONFIG',
  CANCEL_REQUEST: 'CANCEL_REQUEST',
  GET_CARNETS_TEMPORARY: 'GET_CARNETS_TEMPORARY',
  GET_INVOICE: 'GET_INVOICE',
  GET_ACCESS_AREAS: 'GET_ACCESS_AREAS',
  DELETE_CARD_REQUEST: 'DELETE_CARD_REQUEST',

  // Contractor
  GET_CONTRACTOR: 'GET_CONTRACTOR',

  // Payment
  CREATE_PAYMENT_ATTEMPT: 'CREATE_PAYMENT_ATTEMPT',
  PROCESS_PAYMENT: 'PROCESS_PAYMENT',
  PROCESS_PAYMENT_SUBSCRIPTION: 'PROCESS_PAYMENT_SUBSCRIPTION',
  VALIDATE_RNC: 'VALIDATE_RNC',

  // Validate Document
  EXTRACT_INFO_FROM_DOCUMENT: 'EXTRACT_INFO_FROM_DOCUMENT',
  COMPARE_FACES: 'COMPARE_FACES',

  // Exchange Rate
  GET_EXCHANGE_RATE: 'GET_EXCHANGE_RATE',

  // Jim Morrinson
  SEARCH_EMPLOYEE_BY_IMAGE: 'SEARCH_EMPLOYEE_BY_IMAGE',
};
