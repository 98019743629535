<template>
  <v-app style="background: #F4FAF5 !important">
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script setup>
</script>
