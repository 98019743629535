import { defineStore } from 'pinia';
import types from '@/types';
import AuthenticationService from '@/services/AuthenticationService';
import router from '@/router';
import useContractorStore from '@/stores/contractor.store';
import requestStore from '@/stores/request.store';

const AuthService = new AuthenticationService();

const useAuthStore = defineStore('auth', {
  state: () => ({
    userInfo: null,
    isAuthenticated: false,
  }),
  actions: {
    [types.SET_TOKEN](token) {
      localStorage.setItem('token', token.access);
      localStorage.setItem('refresh', token.refresh);
      this.isAuthenticated = true;
    },
    async [types.LOGIN](form) {
      const response = await AuthService.login(form);
      await this[types.SET_TOKEN](response.data);
      await this[types.GET_USER]();
      return response;
    },
    [types.LOGOUT]() {
      delete localStorage.token;
      delete localStorage.refresh;
      requestStore().requests = [];
      useContractorStore().contractor = null;
      this.isAuthenticated = false;
      this.userInfo = null;
      router.push({ name: 'login' });
    },
    async [types.GET_USER]() {
      try {
        const response = await AuthService.userInfo();
        this.userInfo = response.data;
      } catch (error) {
        if (error.response.status === 401) {
          this[types.LOGOUT]();
        }
      }
    },
    async [types.REGISTER](form) {
      return AuthService.register(form);
    },
    [types.IS_LOGGED_IN]() {
      return localStorage.token !== undefined && localStorage.refresh !== undefined;
    },
    async [types.GET_DATA_FROM_PEOPLE_SERVICE](idNumber) {
      return AuthService.getDataFromPeopleService(idNumber);
    },
    async [types.RESET_PASSWORD](form) {
      return AuthService.resetPassword(form);
    },
    async [types.RECOVER_PASSWORD](form) {
      return AuthService.recoverPassword(form);
    },
  },
});

export default useAuthStore;
