import { defineStore, storeToRefs } from 'pinia';
import types from '@/types';
import AppService from '@/services/AppService';
import RequestService from '@/services/RequestService';
import useContractorStore from '@/stores/contractor.store';
import useAuthStore from '@/stores/auth.store';

const useAppService = new AppService();
const useRequestService = new RequestService();

export const useAppStore = defineStore('app', {
  state: () => ({
    notification: {
      title: null,
      message: null,
      color: 'info',
      show: false,
      timex: 10000,
    },
    customerMerchantInfo: {
      sapCustomer: null,
      sapCustomerFiscal: null,
      sapCustomerName: null,

      merchantNumber: null,
      merchantName: null,
      advancePayments: [],
      items: [],
    },
    exchangeRate: null,
  }),
  actions: {
    [types.CLOSE_NOTIFICATION]() {
      this.notification.show = false;
    },
    [types.SHOW_NOTIFICATION]({
      title, message, timex = 10000, type = 'success',
    }) {
      this[types.CLOSE_NOTIFICATION]();
      this.notification.title = title;
      this.notification.message = message;
      this.notification.show = true;
      this.notification.timex = timex;
      this.notification.type = type;
    },
    async [types.GET_FILE_CONFIG](filter = null) {
      const response = await useAppService.getFileConfig();
      if (filter !== null) {
        return response.data.filter((config) => config[filter] === 'X');
      }
      return response;
    },
    async [types.SETUP_APP]() {
      const contractorStore = useContractorStore();
      const authStore = useAuthStore();

      const { contractor } = storeToRefs(contractorStore);
      const { userInfo } = storeToRefs(authStore);

      if (userInfo.value === null) {
        await authStore[types.GET_USER]();
      }

      if (contractor.value === null && contractor !== 'loaded') {
        await contractorStore[types.GET_CONTRACTOR]();
      }

      if (authStore[types.IS_LOGGED_IN]()) {
        this.customerMerchantInfo.sapCustomer = import.meta.env.VITE_SAP_CUSTOMER;
        this.customerMerchantInfo.sapCustomerFiscal = import.meta.env.VITE_SAP_FISCAL_CUSTOMER;
        this.customerMerchantInfo.merchantNumber = import.meta.env.VITE_MERCHANT_NUMBER;
        this.customerMerchantInfo.merchantName = import.meta.env.VITE_MERCHANT_NAME;

        if (this.exchangeRate === null) {
          const exchangeData = await useRequestService.get_exchange_rate();
          this.exchangeRate = exchangeData.rate;
        }
      }
    },
    async [types.GET_CONTRACTOR_MATERIAL](id) {
      return useAppService.getContractorMaterial(id);
    },
  },
});
