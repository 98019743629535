const REQUEST_TYPES = {
  CONTRATISTA: {
    id: 1,
    name: 'Contratista',
  },
  EMISION_CARNET: {
    name: 'Emisión de carnet',
    id: 2,
  },
  RENOVACION_CARNET: {
    id: 3,
    name: 'Renovación de carnet',
  },
};
const REQUEST_STATUSES = {
  VALIDACION_PENDIENTE: {
    id: 1,
    name: 'Validación pendiente',
  },
  PENDIENTE_PAGO: {
    id: 2,
    name: 'Pendiente de pago',
  },
  EN_PROCESO: {
    id: 3,
    name: 'En proceso',
  },
  PENDIENTE_RETIRO: {
    id: 4,
    name: 'Pendiente retiro',
  },
  PENDIENTE_ACTUALIZACION: {
    id: 5,
    name: 'Pendiente actualización',
  },
  RECHAZADO: {
    id: 6,
    name: 'Rechazado',
  },
  APROBADO: {
    id: 7,
    name: 'Aprobado',
  },
  RETIRO: {
    id: 8,
    name: 'Retirado',
  },
  CANCELADO: {
    id: 9,
    name: 'Cancelado',
  },
  PROCESANDO_PAGO: {
    id: 10,
    name: 'Procesando Pago',
  },
  EN_INVESTIGACION: {
    id: 11,
    name: 'En investigación',
  },
};
const REQUEST_IDENTIFICATION_TYPES = {
  CEDULA: {
    id: 1,
    name: 'Cédula',
  },
  PASAPORTE: {
    id: 2,
    name: 'Pasaporte',
  },
  RNC: {
    id: 3,
    name: 'RNC',
  },
  FOTO: {
    id: 4,
    name: 'Foto',
  },
};
const REQUEST_DOCUMENT_TYPES = {
  Cédula: {
    id: 1,
    name: 'Cédula',
    for_request: 'X',
  },
  Pasaporte: {
    id: 2,
    name: 'Pasaporte',
    for_request: 'X',
  },
  RNC: {
    id: 3,
    name: 'RNC',
    for_request: 'X',
  },
  Foto: {
    id: 4,
    name: 'Foto',
    for_request: '',
  },
  Visa: {
    id: 5,
    name: 'Visa',
    for_request: '',
  },
  'Carta de denuncia': {
    id: 6,
    name: 'Carta de denuncia',
    for_request: '',
  },
  Contrato: {
    id: 7,
    name: 'Contrato',
    for_request: '',
  },
};

const VALIDITIES = {
  '3_MESES': {
    id: 2,
    name: '3 MESES',
  },
  '1_ANO': {
    id: 3,
    name: '1 AÑO',
  },
  '30_DIAS': {
    id: 4,
    name: '30 dias',
  },
  REIMPRESION_POR_PERDIDA: {
    id: 5,
    name: 'Reimpresion por perdida',
  },
};

const MEMBRESIA_MATERIALES = {
  SOL_CONTRATISTA_PERSONA_FISICA: {
    id: 1,
    name: 'Solicitud código contratistas p.física',
  },
  REN_CONTRATISTA_PERSONA_FISICA: {
    id: 2,
    name: 'Renovación código contratista p.física',
  },
  SOL_CONTRATISTA_EMPRESA: {
    id: 3,
    name: 'Solicitud código empresas contratistas',
  },
  REV_CONTRATISTA_EMPRESA: {
    id: 4,
    name: 'Renovación código empresas contratistas',
  },
};

export default {
  REQUEST_STATUSES,
  REQUEST_TYPES,
  REQUEST_IDENTIFICATION_TYPES,
  VALIDITIES,
  MEMBRESIA_MATERIALES,
  REQUEST_DOCUMENT_TYPES,
};
