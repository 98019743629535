import SetupData from '@/router/SetupData';

const guardRouter = (router) => {
  router.beforeEach(async (to, from, next) => {
    const tokenExists = localStorage.token && localStorage.refresh;

    if (to.meta.requiresAuth) {
      if (tokenExists) {
        await SetupData();
        next();
      } else {
        next('/auth/login/');
      }
    } else if (to.meta.isAuthPage) {
      if (tokenExists) {
        next('/');
      } else {
        next();
      }
    } else {
      next();
    }
  });
};

export default guardRouter;
